@font-face {
	font-family: 'Gotu';
	src: url('../public/fonts/Gotu-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

:root {
	--primary-color: #1c1547; /* Deep Blue */
	--secondary-color: #673ab7; /* Deep Purple */
	--floral-green: #388e3c; /* Green for Foliage */
	--floral-pink: #ec407a;
	--background-dark: #38383b;
	--text-color: #fffdf8;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	color: var(--text-color);
	line-height: 1.6;
	font-family: 'Gotu', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
}

p {
	margin-bottom: 2rem;
}

a {
	color: var(--secondary-color);
	text-decoration: none;
}

a:hover {
	color: var(--accent-color);
}

.container {
	width: 90%;
	max-width: 1200px;
	margin: auto;
	padding: 20px;
}

.main {
	width: 100%;
}

.text-block {
	padding: 2rem 0rem;
	max-width: 700px;
	margin: 0 auto;
	text-align: justify;
}

.full-width-image {
	width: 100%;
	height: auto;
	filter: brightness(50%);
}

#page-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.dark-section,
.copper-section {
	background-color: var(--background-dark);
	color: var(--text-color);
}

.copper-section {
	background-color: #a15611;
}

.header {
	background-color: var(--primary-color);
	color: var(--text-color);
	padding: 1.8rem 2.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative; /* Needed for absolute positioning of nav */
}

.header-inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-title {
	flex-shrink: 0;
}

.header-nav {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-nav .nav-list {
	list-style-type: none;
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;
}

.header-nav .nav-list li {
	margin: 0 15px;
}

.header-nav .nav-list li a {
	text-decoration: none;
	color: var(--text-color);
	transition: color 0.3s ease;
}

.header-nav .nav-list li a:hover {
	color: var(--accent-color);
}

.site-title {
	color: var(--text-color);
	font-size: 1.5rem;
}

.footer {
	position: relative;
	width: 100%;
	background: var(--primary-color);
	color: var(--text-color);
	text-align: center;
	padding: 1rem 0;
	font-size: 0.8rem;
}

.footer p {
	margin: 0;
}

/* Styles for the painting gallery */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust minmax as needed */
    gap: 16px;
    padding: 20px;
    justify-content: center; /* Centers grid items within the grid when there's extra space */
    width: 100%; /* Ensures the grid takes full width of its container */
}

.painting {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content within each painting div */
    justify-content: center; /* Vertically centers items in the flex container */
    text-align: center; /* Ensures text is centered */
}

.painting img {
    width: 100%; /* Adjust based on your desired width, keeping images responsive */
    max-width: 250px; /* Restricts image size for uniformity */
    height: auto;
    border-radius: 8px;
}

.painting p {
    margin-top: 8px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
	.header-nav {
		position: static;
		transform: none;
		left: auto;
		flex-grow: 1; /* To fill the space if needed */
	}

	.nav {
		flex-direction: column;
	}

	.nav li {
		margin-bottom: 0.5rem;
	}
}

